<div class="bg-white">
  <h2 mat-dialog-title>
    {{ "project-admin.projectHandler.updateProject" | translate }}
  </h2>
  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="mb-3">
        <span class="text-base font-semibold required">{{
          "project-admin.createProjectModal.name" | translate | translate
        }}</span>

        <input
          formControlName="name"
          type="text"
          class="input input-bordered w-full bg-white focus:outline-0 focus:border-primary"
        />
      </div>

      <div class="mb-3">
        <span class="required text-base font-semibold">{{
          "project-admin.createProjectModal.description" | translate
        }}</span>

        <textarea
          formControlName="description"
          class="textarea textarea-bordered textarea-sm w-full bg-white focus:outline-0 focus:border-primary"
          rows="3"
          placeholder="..."
        ></textarea>
      </div>

      <div class="mb-3">
        <label class="form-control">
          <span class="text-base font-semibold">{{
            "project-admin.createProjectModal.skipper" | translate
          }}</span>
          <select
            formControlName="skipper"
            class="select select-bordered bg-white focus:outline-0 focus:border-primary"
            [attr.placeholder]="'project-admin.clientNameLabel' | translate"
          >
            <option value="null">-</option>
            @for (item of skippers(); track item.id) {
              <option [value]="item.id">
                {{ item.firstname }} {{ item.lastname }}
              </option>
            }
          </select>
        </label>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <a class="link link-primary mr-4" (click)="dialogRef.close('no')">{{
      "common.cancel" | translate
    }}</a>
    <button
      type="button"
      class="btn btn-primary text-white"
      (click)="dialogRef.close(form.value)"
    >
      {{ "modal.action.update" | translate }}
    </button>
  </mat-dialog-actions>
</div>
