<div
  class="bg-gray-card p-4"
  [class.m-4]="!isEdit()"
  [class.rounded-xl]="!isEdit()"
  [formGroup]="form"
>
  <h1 class="mb-3 text-lg italic">
    @if (isEdit()) {
      {{ "project-admin.updateSkipper" | translate }} : {{ user()?.username }}
    } @else {
      {{ "project-admin.newSkipper" | translate }}
    }
  </h1>

  <div
    class="flex flex-wrap flex-col md:flex-row justify-end md:justify-between gap-3 md:gap-0"
  >
    <div class="flex flex-wrap flex-col justify-start md:flex-row gap-3">
      <label class="form-control max-w-xs">
        <div class="label">
          <span class="label-text">{{
            "project-admin.partnerTable.partnerName" | translate
          }}</span>
        </div>
        <select
          formControlName="partner"
          class="select select-bordered w-56 select-sm bg-white focus:outline-0 focus:border-primary"
        >
          <option [value]="null">
            {{ "common.placeholder.selectPartner" | translate }}
          </option>

          @for (item of partner(); track $index) {
            <option [value]="item.id">
              {{ item.name }}
            </option>
          }
        </select>
      </label>

      <label class="form-control max-w-xs">
        <div class="label">
          <span class="label-text">{{
            "project-admin.table.id" | translate
          }}</span>
        </div>
        <input
          type="text"
          class="input input-sm w-56 input-bordered bg-white focus:outline-0 focus:border-black"
          formControlName="username"
        />
      </label>

      <label class="form-control max-w-xs">
        <div class="label">
          <span class="label-text">{{
            "project-admin.table.firstname" | translate
          }}</span>
        </div>
        <input
          type="text"
          class="input input-sm w-56 input-bordered bg-white focus:outline-0 focus:border-black"
          formControlName="firstname"
        />
      </label>

      <label class="form-control max-w-xs">
        <div class="label">
          <span class="label-text">{{
            "project-admin.table.familyname" | translate
          }}</span>
        </div>
        <input
          type="text"
          class="input input-sm w-56 input-bordered bg-white focus:outline-0 focus:border-black"
          formControlName="lastname"
        />
      </label>

      <label class="form-control max-w-xs">
        <div class="label">
          <span class="label-text">{{
            "project-admin.table.email" | translate
          }}</span>
        </div>
        <input
          type="email"
          class="input input-sm w-56 input-bordered bg-white focus:outline-0 focus:border-black"
          formControlName="email"
        />
      </label>

      @if (!isEdit()) {
        <label class="form-control max-w-xs">
          <div class="label">
            <span class="label-text">{{
              "project-admin.table.password" | translate
            }}</span>
          </div>
          <input
            type="password"
            class="input input-sm w-56 input-bordered bg-white focus:outline-0 focus:border-black"
            formControlName="password"
            (input)="updatePasswordStrength()"
          />

          @if (passwordStrengthMessage()) {
            <div class="mt-1">
              <span
                [ngClass]="{
                  'text-red-500': passwordStrength() === 'weak',
                }"
              >
                @if (passwordStrength() === "weak") {
                  {{ "common.tooEasyPassword" | translate }}
                }
              </span>
            </div>
          }
        </label>

        <label class="form-control max-w-xs">
          <div class="label">
            <span class="label-text">{{
              "project-admin.table.confirmPassword" | translate
            }}</span>
          </div>
          <input
            type="password"
            class="input input-sm w-56 input-bordered bg-white focus:outline-0 focus:border-black"
            formControlName="password_confirm"
          />

          @if (
            form.get("password_confirm")?.touched &&
            form.get("password_confirm")?.errors
          ) {
            <div class="text-red-500 mt-1">
              {{ "common.passwordNotMatch" | translate }}
            </div>
          }
        </label>
      }

      <div class="flex gap-2 items-end">
        <button
          class="btn btn-square btn-sm btn-outline btn-success"
          [disabled]="form.invalid"
          (click)="createNew()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m4.5 12.75 6 6 9-13.5"
            />
          </svg>
        </button>

        <button
          class="btn btn-square btn-sm btn-outline btn-error"
          (click)="cancel.emit()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
