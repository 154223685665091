import { Partner } from './../../../core/models/partnersResponse';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import {
  AbstractControlOptions,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { UserPostParam } from '../../../core/models/user-post-param.model';
import { User } from '../../../core/models/user.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-add-skipper-form',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, NgClass],
  templateUrl: './add-skipper-form.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSkipperFormComponent implements OnInit {
  ngOnInit(): void {
    if (this.isEdit() && this.user()) {
      this.form.get('password')?.clearValidators();
      this.form.get('password_confirm')?.clearValidators();
      this.form.patchValue({
        id: this.user()?.id,
        firstname: this.user()?.firstname,
        lastname: this.user()?.lastname,
        username: this.user()?.username,
        email: this.user()?.email,
        active: this.user()?.active,
        partner: this.user()?.partner_id,
      });
    } else {
      this.form.get('password')?.setValidators(Validators.required);
      this.form.get('password_confirm')?.setValidators(Validators.required);
    }
  }

  fb = inject(UntypedFormBuilder);
  partner = input.required<Partner[]>();
  passwordStrength = signal<string>('weak');
  passwordStrengthMessage = signal<string>('');
  isEdit = input<boolean>(false);
  user = input<User | undefined>();
  create = output<UserPostParam>();
  cancel = output<void>();

  resetPassword = output<string>();

  form = this.fb.group(
    {
      id: [null],
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      username: [null, Validators.required],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          this.#patternValidator(/\d/, { hasNumber: true }),
          this.#patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          this.#patternValidator(/[!@#$%^&*(),.?":{}|<>]/, {
            hasSpecialCharacters: true,
          }),
        ]),
      ],
      password_confirm: [''],
      email: [null, Validators.required],
      active: [true],
      partner: [null, Validators.required],
    },
    {
      validators: this.#mustMatch('password', 'password_confirm'),
    } as AbstractControlOptions,
  );
  canResetForm = input<boolean>(false);

  private readonly resetForm = effect(() => {
    if (this.canResetForm()) {
      this.form.reset();
    }
  });

  createNew() {
    if (this.form.valid) {
      this.create.emit(this.form.value);
    }
  }

  #mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  #patternValidator(regex: RegExp, error: Record<string, boolean>) {
    return (control: any) => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  updatePasswordStrength() {
    const password = this.form.get('password')?.value || '';
    if (
      password.length < 6 ||
      !/\d/.test(password) ||
      !/[A-Z]/.test(password) ||
      !/[!@#$%^&*(),.?":{}|<>]/.test(password)
    ) {
      this.passwordStrength.set('weak');
      this.passwordStrengthMessage.set('Mot de passe trop facile');
    } else if (
      password.length >= 6 &&
      /\d/.test(password) &&
      /[A-Z]/.test(password)
    ) {
      this.passwordStrength.set('medium');
      this.passwordStrengthMessage.set('Mot de passe correct');
    } else if (
      password.length >= 8 &&
      /\d/.test(password) &&
      /[A-Z]/.test(password) &&
      /[!@#$%^&*(),.?":{}|<>]/.test(password)
    ) {
      this.passwordStrength.set('strong');
      this.passwordStrengthMessage.set('Mot de passe fort');
    }
  }
}
