import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  signal,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectService } from '../../../../core/services/project.service';
import { UsersService } from '../../../../core/services/users.service';
import { Skipper } from '../../../../core/models/skipper.model';

@Component({
  selector: 'app-update-project-modal',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, MatDialogModule],
  templateUrl: './update-project-modal.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateProjectModalComponent {
  dialogRef = inject(MatDialogRef<UpdateProjectModalComponent>);
  readonly #projectService = inject(ProjectService);
  readonly #usersService = inject(UsersService);
  skippers = signal<Skipper[]>([]);
  readonly #fb = inject(UntypedFormBuilder);
  data = inject(MAT_DIALOG_DATA);

  projectId = effect(() => {
    if (this.data.projectId) {
      this.getProjects();
    }
  });

  clientId = effect(() => {
    if (this.data.clientId) {
      this.#projectService
        .getDataForAddProject(this.data.clientId)
        .subscribe((x) => this.skippers.set(x.data.skippers));
    }
  });

  getProjects() {
    this.#projectService.getProjectById(this.data.projectId).subscribe((x) => {
      this.form.patchValue({
        name: x?.name,
        description: x.description,
        skipper: x.skipper_id,
      });
    });
  }

  form = this.#fb.group({
    name: [null],
    description: [null],
    skipper: [null],
  });

  close() {
    this.dialogRef.close();
  }
}
